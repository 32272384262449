import React from 'react';
import styled, { css } from 'styled-components';

import Box, { ShadowBoxStyle } from 'components/forms/Box';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import { getComponentSettings } from 'libs/content';
import { generateNthChildCSS } from 'libs/SharedStyling';

//#region Styling

const Wrapper = styled.div`
	${p =>
		p.theme.media.medium(css`
			display: flex;
			gap: 20px;
		`)}

	${p =>
		p.$parentshadow === 'true' &&
		css`
			${ShadowBoxStyle}
			${p =>
				p.theme.media.medium(css`
					padding: 45px 20px;
				`)}
				${p =>
				p.theme.media.mediumOnly(css`
					flex-wrap: wrap;
				`)}
			.box {
				padding: 0;
				min-width: auto;
				margin-bottom: 30px;
				${p =>
					p.theme.media.mediumOnly(css`
						flex: 1 1 calc(50% - 20px);
						width: auto;
					`)}
				${p =>
					p.theme.media.medium(css`
						padding: 0 20px;
						margin-bottom: 0;
					`)}
				&:last-of-type {
					margin-bottom: 0;
				}
				.component__title-and-text > .text {
					margin-bottom: 0;
					> div > p {
						margin-bottom: 0;
					}
				}
			}
		`}

	
	.box {
		width: 100%;
		text-align: left;

		${p =>
			p.theme.media.smallOnly(css`
				&.shadow:not(:last-of-type) {
					margin-bottom: 20px;
				}
			`)}

		&.shadow {
			${p =>
				p.theme.media.smallOnly(css`
					&:not(:first-of-type) {
						margin-top: 30px;
					}
				`)}
			.text {
				margin-bottom: 0;
				ul:last-of-type,
				ol:last-of-type,
				p:last-of-type {
					margin-bottom: 0;
				}
				p {
					white-space: pre-line;
				}
			}
		}

		.component__job-vacancies {
			width: 100%;
		}
	}

	&.shadow-none:not(.parent-shadow) {
		${p =>
			p.theme.media.large(css`
				align-items: flex-start !important;
			`)}
		.box {
			${generateNthChildCSS(3)}
			${p =>
				p.theme.media.medium(css`
					padding-top: 0;
				`)}
			&:not(:last-of-type) {
				${p =>
					p.theme.media.mediumDown(css`
						padding-top: 0;
						padding-bottom: 20px;
					`)};
			}
		}
	}

	&:not(.shadow-all):not(.parent-shadow) {
		${p =>
			p.theme.media.mediumDown(css`
				flex-direction: column;
			`)}
		${p =>
			p.theme.media.large(css`
				align-items: center;
				justify-content: space-between;
				gap: 60px;
				.box.no-shadow {
					flex: 1 1 calc(100% - 488px);
					flex-grow: 1;
				}
				.box.shadow {
					flex: 0 0 488px;
				}
			`)}
		// Box with Job vacancies
		.box.box__job-vacancies {
			${p =>
				p.theme.media.large(css`
					flex: 0 0 590px;
				`)}
			.component__title-and-text h3 {
				margin-bottom: 10px;
			}
			.loading.spacing {
				padding-top: 30px;
				padding-bottom: 10px !important;
				> div {
					padding: 0;
				}
			}
			.no-vacancies {
				box-shadow: none;
				padding: 20px 0 0;
			}
			ul {
				li {
					background-color: transparent;
					border-bottom: 1px solid ${p => p.theme.colors.grey300};
					padding: 20px 0;
					a[type='button'] {
						margin: 0 !important;
					}
					&:first-of-type {
						padding-top: 0;
					}
					&:last-of-type {
						border-bottom: none;
						padding-bottom: 0;
					}
				}
			}
		}
	}
`;

//#endregion

/**
 * Represents a grid of columns, each with a title and text and optional box-shadow.
 * @param {array} columns - The columns to show
 * @param {string} headinglevel - The heading level of the columns
 * @param {boolean} parentshadow - If the parent div of the columns should have a box-shadow
 * @returns {ReactElement|null} The Columns component
 */
export default function Columns({
	columns = [],
	headinglevel = 'h3',
	parentshadow = 'false',
}) {
	if (!columns || columns.length === 0) return null;

	// Check if all columns have shadow
	const allHaveShadow = columns?.every(
		c => c?.settings?.includes('Skygge bak innhold') || false
	);

	// Check if all columns do not have shadow
	const allWithoutShadow = columns?.every(
		c => !c?.settings?.includes('Skygge bak innhold') || false
	);

	// Set the class name based on the shadow settings
	let className = parentshadow === 'true' ? 'parent-shadow boxes' : 'boxes';
	if (allHaveShadow) {
		className += ' shadow-all';
	} else if (allWithoutShadow) {
		className += ' shadow-none';
	}

	return (
		<Wrapper
			$totalCount={columns?.length}
			$parentshadow={parentshadow}
			className={className}>
			{columns.map((column, i) => {
				if (!column?.title && !column?.text) return null;

				const componentSettings = getComponentSettings({
					settings: column?.settings,
				});

				// Check if the column has __typename "ContentfulKomponentLedigeStillinger"
				const hasJobVacancies = column?.text?.references?.some(
					z => z?.__typename === 'ContentfulKomponentLedigeStillinger'
				);

				return (
					<Box
						key={i}
						maxPerRow={columns?.length > 3 ? 3 : columns?.length}
						className={hasJobVacancies ? 'box__job-vacancies' : ''}
						shadow={
							parentshadow !== 'true' &&
							componentSettings?.shadow === 'true'
								? 'true'
								: 'false'
						}>
						<TitleAndText
							{...column}
							headinglevel={headinglevel}
							nested={true}
							noWrappers={true}
						/>
					</Box>
				);
			})}
		</Wrapper>
	);
}
